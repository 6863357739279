import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ActivationPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.activationRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.activationActivation,
    );
    this.importAutocomplete = permissionChecker.match(
      Permissions.values.activationAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.activationCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.activationEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.activationDestroy,
    );
  }
}
