//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ActivationModel } from '@/modules/activation/activation-model';
import { ActivationPermissions } from '@/modules/activation/activation-permissions';
import i18n  from '@/vueI18n';
// import {getLanguageCode} from '@/i18n';
import moment from 'moment';
// import { LocalStorage } from 'quasar';
// import TableViewPage from '@/shared/components/table-view-page.vue';
// import QrcodeVue from 'qrcode.vue'
// import ActivationListToolbar from '@/modules/activation/components/activation-list-toolbar';

const { fields } = ActivationModel;

export default {
  name: 'app-activation-list-table',

  components: {
    // [ActivationListToolbar.name]: ActivationListToolbar,
    // [TableViewPage.name]: TableViewPage,
    // QrcodeVue,
  },

  mounted() {
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      filter: '',
      selected: [],
      // language: 'en',
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      columns: [
        {
          name: 'id',
          field: 'id',
          label: '#',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
          {
          name: 'serialNumber',
          field: 'serialNumber',
          label: 'entities.activation.fields.serialNumber',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'sellerName',
          field: 'sellerName',
          label: 'entities.activation.fields.sellerName',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
        },
        {
          name: 'userName',
          field: 'activator',
          label: 'entities.activation.fields.userName',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'phoneNumber',
          field: 'activator',
          label: 'entities.activation.fields.phoneNumber',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'userType',
          field: 'activator',
          label: 'entities.activation.fields.userType',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'status',
          field: 'activator',
          label: 'entities.activation.fields.userStatus',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'activationStatus',
          field: 'limitExceeded',
          label: 'entities.activation.fields.activationStatus',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'numberOfActivations',
          field: 'items',
          label: 'entities.activation.fields.numberOfActivations',
          align: 'center',
          required: true,
          // sortable: true,
        },
        // {
        //   name: 'exportedAt',
        //   field: 'exportedAt',
        //   label: i18n('entities.importer.fields.exportedAt'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        // {
        //   name: 'warrantyStatus',
        //   field: 'warrantyStatus',
        //   label: i18n('entities.importer.fields.warrantyStatus'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        // {
        //   name: 'activationDate',
        //   field: 'activationDate',
        //   label: i18n('entities.importer.fields.activationDate'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        // {
        //   name: 'expirationDate',
        //   field: 'expirationDate',
        //   label: i18n('entities.importer.fields.expirationDate'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        // {
        //   name: 'activationdBy',
        //   field: 'activationdBy',
        //   label: i18n('entities.importer.fields.activationdBy'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        // {
        //   name: 'clientPhone',
        //   field: 'activatedBy',
        //   label: i18n('entities.importer.fields.clientPhone'),
        //   align: 'center',
        //   required: true,
        //   // sortable: true,
        // },
        {
          name: 'updatedAt',
          field: 'updatedAt',
          label: 'common.lastUpdated',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'time',
          field: 'updatedAt',
          label: 'common.time',
          align: 'center',
          required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      rows: 'activation/list/rows',
      count: 'activation/list/count',
      // pagination: 'activation/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'activation/list/loading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'activation/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    hasPermissionToEdit() {
      return new ActivationPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new ActivationPermissions(this.currentUser)
        .destroy;
    },

    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    userTypeColor() {
      return (user) => {
        let color 
        const type = user.userType
        switch (type) {
          case 'admin':
            color = '#00B2E3'
            break;
          case 'client':
            color = '#2FA84F'
            break;
          case 'seller': 
            color = '#FD3018'
            break
          default:
            color = '#003A5D'
            break;
        }
        return color
      }
    },
    language(){
      return i18n.locale
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'activation/list/doChangeSort',
      doChangePaginationCurrentPage: 'activation/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'activation/list/doChangePaginationPageSize',
      doMountTable: 'activation/list/doMountTable',
      doDestroy: 'activation/destroy/doDestroy',
      doDestroyAll: 'activation/destroy/doDestroyAll',
      updateQuantityByTransaction:'activation/destroy/updateQuantityByTransaction',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = ActivationModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterActivationStatus(row){
      // const val = ActivationModel.presenter(row, fieldName);
      return row.limitExceeded ? this.i18n(`entities.activation.enumerators.activationStatus.exceeded`) : this.i18n(`entities.activation.enumerators.activationStatus.normal`);

    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? this.i18n(`entities.activation.enumerators.userType.${val[0].value}`) : 'ـــ';
    },

    // presenterBoolean(row, fieldName) {
    //   const val = ActivationModel.presenter(row, fieldName);
    //   return val ? this.fields[fieldName].yesLabel : this.fields[fieldName].NoLabel;
    // },
    presenterMap(row, fieldName) {
      const val = ActivationModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = ActivationModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(ActivationModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = ActivationModel.presenter(row, fieldName)
      let n = row[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.language).format('hh:mm a')
    },
    presenterUser(row, fieldName, key) {
      const user = ActivationModel.presenter(row, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      if (key == 'userType') {
        return this.presenterEnum(user, key)
      }
      if (key == 'disabled') {
         return user[key] ? this.i18n(this.fields[key].label) : this.i18n('entities.activation.fields.enabled');
        // return user[key] ? this.fields[key].yesLabel : this.fields[key].noLabel;
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterSellerName(row) {
      return row.items[0].seller && row.items[0].seller.name ? row.items[0].seller.name : 'null'

      // const user = ActivationModel.presenter(row, fieldName);
      // if (key == 'avatars' || key == 'avatar') {
      //   let avatar = null
      //   if (user && Array.isArray(user[key])) {
      //     avatar = user[key].length ? user[key][0].publicUrl : null
      //   } else if (user && user[key]) {
      //     avatar = user[key].publicUrl 
      //   }
      //   return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      // }
      // if (key == 'userType') {
      //   return this.presenterEnum(user, key)
      // }
      // if (key == 'disabled') {
      //   return user[key] ? this.fields[key].yesLabel : this.fields[key].noLabel;
      // }
      // return user && user[key] ? user[key] : 'ـــ'; 
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'view':
          this.dialogType = 'view'
          break;
        case 'deleteAll':
          this.dialogType = 'deleteAll'
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },
    async doDestroyAllWithConfirm(ids) {
      try {
        await this.doDestroyAll(ids);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
    async doDestroyWithConfirm(id) {
      try {
        // await this.updateQuantityByTransaction({collectionName:this.selectedRow.itemType,docId:this.selectedRow.itemId,quantity:-1})
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
  },
};
