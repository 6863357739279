//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActivationListToolbar from '@/modules/activation/components/activation-list-toolbar';
import ActivationListTable from '@/modules/activation/components/activation-list-table.vue';

import { ActivationPermissions } from '@/modules/activation/activation-permissions';
import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n';
import { routerAsync } from '@/app-module';
import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  name: 'app-activation-list-page',

  components: {
    [ActivationListToolbar.name]: ActivationListToolbar,
    [ActivationListTable.name]: ActivationListTable,
  },

  // async created() {
  //   // await firebase.fiactivation().collection('items').onSnapshot({ includeMetadataChanges: true }, async querySnapshot => {
  //   this.unsubscribeActivationListener = firebase.firestore().collection('activation').onSnapshot(async () => {
  //     //  console.log(`Received query snapshot of size ${querySnapshot.size}`);
  //     // await this.doFilterInBackground();
  //     await this.doFilter();
  //   }, err => {
  //      console.log(`Encountered error: ${err}`);
  //   });
  // },
  async mounted() {
    console.log('enter 1');
    await this.doFilter();
    this.firstFilter = true
    this.unsubscribeActivationListener = firebase.firestore().collection('activation').onSnapshot(async () => {
      console.log('enter 2');
      this.firstFilter ? this.firstFilter = false : await this.doFilter();
    }, err => {
       console.log(`Encountered error: ${err}`);
    });
    
  },
  beforeDestroy(){
    if(this.unsubscribeActivationListener) this.unsubscribeActivationListener()
  },
  data() {
    return {
      firstFilter: false,
      unsubscribeActivationListener: undefined,
      // confirm: false,
    };
  },
  computed: {
    ...mapGetters({
      rows: 'activation/list/rows',
      count: 'activation/list/count',
      loading: 'activation/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new ActivationPermissions(this.currentUser).create;
    },
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('entities.activation.label') 
    },
  },
  methods: {
    ...mapActions({
      doFetch: 'activation/list/doFetch',
      doFetchInBackground: 'activation/list/doFetchInBackground',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
    // doOpenModal() {
    //   this.confirm = true
    // },
    routeTo() {
      routerAsync().push('/activation/scan');
    },
    async doFilter() {
      if (!this.rows.length) {
        this.doFilterWithLoading();
      } else {
        this.doFilterInBackground()
      }
    },
    async doFilterWithLoading() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
  },
};
